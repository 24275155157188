var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'DashboardHome',
            }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("會員管理中心")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("會員等級條件")])],1)],1)])]),_c('b-card',[_c('h4',{staticClass:"mb-2 mb-xl-0 mr-2 font-weight-bold"},[_vm._v("會員等級條件")]),_c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col-12 col-xl-4"}),_c('div',{staticClass:"\n          col-12 col-xl-8\n          d-flex flex-xl-row flex-column\n          align-items-start align-items-xl-center\n          justify-content-end\n        "},[(_vm.checkPermission([_vm.consts.CUSTOMER_LEVEL_RULE_CREATE]))?_c('b-button',{staticClass:"flex-shrink-0 mb-2 mb-xl-0 mr-2",attrs:{"variant":"primary"},on:{"click":_vm.goToCreatePage}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v("新增會員等級條件")]):_vm._e(),_c('b-form-select',{staticClass:"mb-xl-0 w-50",attrs:{"options":_vm.customerLevelOptions,"placeholder":"請選擇會員等級"},on:{"change":_vm.handlePageReset},model:{value:(_vm.query.level_id),callback:function ($$v) {_vm.$set(_vm.query, "level_id", $$v)},expression:"query.level_id"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-table',{attrs:{"striped":"","hover":"","responsive":"","items":_vm.rules,"fields":_vm.fields,"busy":_vm.isLoading},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"cell(is_enabled)",fn:function(data){return [_c('b-badge',{staticClass:"mr-1",attrs:{"variant":!!data.item.is_enabled === true ? 'success' : 'danger'}},[_vm._v(_vm._s(!!data.item.is_enabled === true ? "啟用" : "停用"))])]}},{key:"cell(actions)",fn:function(data){return [(_vm.checkPermission([_vm.consts.CUSTOMER_LEVEL_RULE_LIST]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-primary","to":{
                name: 'CustomerLevelRuleView',
                params: { id: data.item.id },
              }}},[_c('span',{staticClass:"mdi mdi-eye"}),_vm._v(" 查看")]):_vm._e(),(_vm.checkPermission([_vm.consts.CUSTOMER_LEVEL_RULE_EDIT]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-warning","to":{
                name: 'CustomerLevelRuleEdit',
                params: { id: data.item.id },
              }}},[_c('span',{staticClass:"mdi mdi-lead-pencil"}),_vm._v(" 編輯")]):_vm._e(),(_vm.checkPermission([_vm.consts.CUSTOMER_LEVEL_RULE_DELETE]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-danger"},on:{"click":function($event){return _vm.handleDelete(data.item)}}},[_c('span',{staticClass:"mdi mdi-delete"}),_vm._v(" 刪除")]):_vm._e()]}}])})],1)]),_c('CustomPagination',{attrs:{"currentPage":_vm.query.page,"totalRows":_vm.totalRows,"perPage":_vm.query.per_page},on:{"page-change":_vm.handlePageChange,"per-page-change":_vm.handlePerPageChange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }